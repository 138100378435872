import React from 'react';

import {
  Filter,
  FunctionField,
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import QueueEmpty from './empty';

import StringUtil from '../../utils/string';
import DateUtil from '../../utils/date';
import { TOKEN_KEY } from '../../utils/constants';

const { REACT_APP_API_URL } = process.env;

const download = ({ id }) => {
  const url = `${REACT_APP_API_URL}queue/${id}/download`;
  const token = localStorage.getItem(TOKEN_KEY);

  fetch(url, {
    method: 'GET',
    redirect: 'manual',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(async (resp) => {
    const json = await resp.json();
    window.open(json.url);
  });
};

const QueueFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Type" source="type" allowEmpty />
    <TextInput label="Company ID" source="companyId" allowEmpty />
    <TextInput label="POS ID" source="posId" allowEmpty />
    <TextInput label="Ping ID" source="pingId" allowEmpty />
    <TextInput label="Status" source="status" allowEmpty />
    <TextInput label="Created By" source="createdBy" allowEmpty />

    <TextField source="createdBy" label="Created By" />
    <TextField source="updatedBy" label="Updated By" />
  </Filter>
);

const DownloadButton = ({ record }) => {
  if (!(record && record.result)) return <div />;

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => download(record)}
    >
      Download
    </Button>
  );
};

const QueueList = (props) => {
  const getAuthor = (record) => {
    let author = '';
    if (record) {
      author = record.updatedBy ? record.updatedBy : record.createdBy;
    }

    return StringUtil.titleCase(author);
  };

  return (
    <List
      empty={<QueueEmpty />}
      filters={<QueueFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      title="Jobs"
      {...props}
    >
      <Datagrid>
        <TextField label="Company Name" source="companyName" />
        <TextField label="Pos Name" source="posName" />
        <FunctionField
          label="Command"
          render={(record) => {
            if (
              record.type === 'command' &&
              record.commandName &&
              record.commandName !== ''
            ) {
              return record.commandName;
            }
            return `${StringUtil.titleCase(record.type)}`;
          }}
        />
        <FunctionField
          label="Status"
          render={(record) => {
            if (record.status === 'done') {
              return <span style={{ color: green[500] }}>Success</span>;
            } else if (record.status === 'failed') {
              return <span style={{ color: red[500] }}>Failed</span>;
            }
            return `${StringUtil.titleCase(record.status)}`;
          }}
        />
        <FunctionField
          label="Updated"
          render={(record) => `${DateUtil.dateTimeFormat(record.updatedAt)}`}
        />
        <DownloadButton />
        <FunctionField
          label="Updated By"
          render={(record) => getAuthor(record)}
        />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default QueueList;
