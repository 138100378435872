import React from 'react';

import {
  Filter,
  FunctionField,
  List,
  Datagrid,
  TextField,
  TextInput,
} from 'react-admin';

import PingEmpty from './empty';

import DateUtil from '../../utils/date';

const PingFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Company ID" source="companyId" allowEmpty />
    <TextInput label="Pos ID" source="posId" allowEmpty />
    <TextInput label="Job ID" source="queueId" allowEmpty />
  </Filter>
);

const PingList = (props) => {
  return (
    <List
      title="Pings"
      empty={<PingEmpty />}
      filters={<PingFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <TextField label="Company Name" source="companyName" />
        <TextField label="POS Name" source="posName" />
        <TextField label="Version" source="version" />
        <FunctionField
          label="Created"
          render={(record) => `${DateUtil.dateTimeFormat(record.createdAt)}`}
        />
        <TextField label="Job ID" source="acceptedJobId" />
      </Datagrid>
    </List>
  );
};

export default PingList;
