import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { Title } from 'react-admin';

const Dashboard = () => (
  <>
    <Title title="Dashboard" />
    <Card>

      <CardHeader title="Welcome to Equip Support" />
      <CardContent>Administration dashboard.</CardContent>
    </Card>
  </>
);

export default Dashboard;
