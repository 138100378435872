import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { useDataProvider } from 'react-admin';

import StringUtil from '../../../utils/string';
import DateUtil from '../../../utils/date';
import { TOKEN_KEY } from '../../../utils/constants';

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function QueueTable({ record = {}, refresh }) {
  const dataProvider = useDataProvider();

  // API to load 5 latest queue
  const [queues, setQueues] = useState([]);

  const selectedCompanyId = record.companyId;
  const selectedPosId = record.posId;

  const download = ({ id }) => {
    const url = `${REACT_APP_API_URL}queue/${id}/download`;
    const token = localStorage.getItem(TOKEN_KEY);

    fetch(url, {
      method: 'GET',
      redirect: 'manual',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(async (resp) => {
      const json = await resp.json();
      window.open(json.url);
    });
  };

  useEffect(() => {
    if (!(selectedCompanyId && selectedPosId)) return;

    const filter = { companyId: selectedCompanyId, posId: selectedPosId };
    const pagination = { page: 1, perPage: 5 };
    const sort = { field: 'createdAt', order: 'DESC' };

    dataProvider
      .getList('queue', { filter, pagination, sort })
      .then(({ data }) => {
        setQueues(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider, selectedCompanyId, selectedPosId, refresh]);

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Accepted</TableCell>
            <TableCell>Uploaded</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {queues.map((row) => (
            <TableRow key={`${row.companyId}-${row.posId}-${row.createdAt}`}>
              <TableCell component="th" scope="row">
                {StringUtil.titleCase(row.type)}
              </TableCell>
              <TableCell>{DateUtil.dateTimeFormat(row.createdAt)}</TableCell>
              <TableCell>
                {row.acceptedAt
                  ? DateUtil.dateTimeFormat(row.acceptedAt)
                  : null}
              </TableCell>
              <TableCell>
                {row.doneAt ? DateUtil.dateTimeFormat(row.doneAt) : null}
              </TableCell>
              <TableCell>{StringUtil.titleCase(row.status)}</TableCell>
              <TableCell>
                {row.result && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => download(row)}
                  >
                    Download
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
