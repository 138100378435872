const getCronList = async ({ params, api }) => {
	const { filter, pagination, sort } = params;

	const queryParams = [];

	if (pagination) {
		queryParams.push(`page=${pagination.page}`);
		queryParams.push(`limit=${pagination.perPage}`);
	}

	if (sort)
		queryParams.push(`sort=${encodeURIComponent(JSON.stringify(sort))}`);

	if (filter && JSON.stringify(filter) !== '{}')
		queryParams.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);

	return await api(`cron?${queryParams.join('&')}`).then((data) => {
		return Promise.resolve({
			data: data.list,
			total: data.total,
		});
	});
};

export default getCronList;
