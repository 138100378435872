import React from 'react';

import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import Button from '@material-ui/core/Button';

import StringUtil from '../../utils/string';
import DateUtil from '../../utils/date';
import { TOKEN_KEY } from '../../utils/constants';

const { REACT_APP_API_URL } = process.env;

const download = ({ id }) => {
  const url = `${REACT_APP_API_URL}queue/${id}/download`;
  const token = localStorage.getItem(TOKEN_KEY);

  fetch(url, {
    method: 'GET',
    redirect: 'manual',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(async (resp) => {
    const json = await resp.json();
    window.open(json.url);
  });
};

const FormTitle = ({ record }) => {
  let title = '';
  if (record) {
    if (record.companyName && record.posName) {
      title = `${record.companyName} - ${record.posName}`;
    } else {
      title = `${record.companyId} - ${record.posId}`;
    }

    return <span>{`${title}`}</span>;
  };
};

const DownloadButton = ({ record }) => {
  if (!(record && record.result)) return <div />;

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => download(record)}
    >
      Download
    </Button>
  );
};

const QueueShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <FunctionField
          label="Status"
          render={(record) => `${StringUtil.titleCase(record.status)}`}
        />
        <FunctionField
          label="Type"
          render={(record) => `${StringUtil.titleCase(record.type)}`}
        />

        <TextField source="command" label="SQL Query" />

        <TextField source="companyName" label="Company Name" />

        <TextField source="companyId" label="Company ID" />

        <TextField source="posName" label="POS Name" />

        <TextField source="posId" label="POS ID" />

        <FunctionField
          label="Accepted"
          render={(record) => `${DateUtil.dateTimeFormat(record.acceptedAt)}`}
        />
        <TextField source="pingId" label="Accepted by Ping ID" />
        <FunctionField
          label="Uploaded"
          render={(record) => `${DateUtil.dateTimeFormat(record.doneAt)}`}
        />
        <DownloadButton />
      </SimpleShowLayout>
    </Show>
  );
};

export default QueueShow;
