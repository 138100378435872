import React from 'react';

import { Create, SimpleForm, TextInput, required, regex } from 'react-admin';

const FormTitle = () => {
  return <span>Create POS Terminal</span>;
};

const validateCompanyId = regex(
  /^[a-z0-9_]+$/i,
  'Invalid company id. Alphanumeric characters and underscore only.'
);
const validatePosId = regex(/^\d+$/, 'Invalid POS id. Numbers only.');

const PosCreate = (props) => (
  <Create title={<FormTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput
        source="companyName"
        label="Company Name"
        validate={[required()]}
      />
      <TextInput
        source="companyId"
        label="Company ID"
        validate={[validateCompanyId, required()]}
      />
      <TextInput source="posName" label="POS Name" validate={[required()]} />
      <TextInput
        source="posId"
        label="POS ID"
        validate={[validatePosId, required()]}
      />
      <TextInput
        source="outletName"
        label="Outlet Name"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

export default PosCreate;
