const titleCase = (text) => {
  try {
    if (typeof text !== 'string') return text;

    return text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  } catch (e) {
    return text;
  }
};

export default {
  titleCase
}