import React from 'react';

import { Show, SimpleShowLayout, TextField } from 'react-admin';

const FormTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const CommandShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <TextField
          source="name"
          label="Name"
        />

        <TextField
          source="sql"
          label="SQL Query"
        />
      </SimpleShowLayout>
    </Show>
  )
};

export default CommandShow;
