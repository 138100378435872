import React from 'react';

import { Edit, SimpleForm, TextInput, required, regex } from 'react-admin';

import EditToolbar from '../common/adminOnlyDeleteToolbar';

const FormTitle = ({ record }) => {
  return (
    <span>Edit {record ? `${record.companyName} - ${record.posId}` : ''}</span>
  );
};

const validateCompanyId = regex(
  /^[a-z0-9_]+$/i,
  'Invalid company id. Alphanumeric characters  and underscore only.'
);
const validatePosId = regex(/^\d+$/, 'Invalid POS id. Numbers only.');

const PosEdit = ({ permissions, ...props }) => (
  <Edit title={<FormTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar permissions={permissions} />}>
      <TextInput
        source="companyName"
        label="Company Name"
        validate={[required()]}
      />
      <TextInput
        source="companyId"
        label="Company ID"
        validate={[validateCompanyId, required()]}
      />
      <TextInput source="posName" label="POS Name" validate={[required()]} />
      <TextInput
        source="posId"
        label="POS ID"
        validate={[validatePosId, required()]}
      />
      <TextInput
        source="outletName"
        label="Outlet Name"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

export default PosEdit;
