import React from 'react';

import {
  BooleanInput,
  Create,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import CronTemplateNote from '../widget/cronTemplateNote';

import cronFormatValidator from '../utils/cronFormatValidator';

const validator = (values) => {
  const errors = {};

  if (values && values.cronExpression) {
    const isValidCronExpression = cronFormatValidator({
      cronExpression: values.cronExpression,
    });
    if (!isValidCronExpression) {
      errors.cronExpression = ['Invalid format'];
    }
  }

  return errors;
};

const FormTitle = ({ record }) => {
  return <span>Create Cron Template</span>;
};

const CronTemplateCreate = (props) => (
  <Create title={<FormTitle />} {...props}>
    <SimpleForm redirect="list" validate={validator}>
      <TextInput source="name" validate={[required()]} />

      <BooleanInput label="UTC Time" source="followUtc" />

      <TextInput
        source="cronExpression"
        label="Cron Expression"
        placeholder="0 4 * * *"
        validate={[required()]}
      />

      <CronTemplateNote />
    </SimpleForm>
  </Create>
);

export default CronTemplateCreate;
