const deleteCommand = async ({ params, api }) => {
  const { previousData } = params;
  const { id } = previousData;
  return await api(`commands/${id}`, { body: { id }, method: 'DELETE' })
    .then((res) => {
      return Promise.resolve({ data: res });
    })
};

export default deleteCommand;
