
const fields = [
  'Minutes',
  'Hours',
  'Day-of-month',
  'Month',
  'Day-of-week'
]

const parseOneRule = (cronExpArray, index, min, max) => {
  const field = cronExpArray[index];

  const isNumber = !isNaN(field);

  if (!isNumber && field !== '*') {
    if (field.includes('/')) {
      if (index === 4) {
        throw new Error('Incorrect use of /');
      }
    }
  }

  if (isNumber) {
    const intField = parseInt(field);
    if (intField < min || intField > max) {
      throw new Error(`Value out of range for field ${fields[index]}`);
    }
  }

  return true;
};

const cronFormatValidator = ({ cronExpression }) => {
  if (!cronExpression) return false;

  const cronExpArray = cronExpression.split(' ');
  if (cronExpArray.length !== 5) {
    // Incorrect number of fields.
    return false;
  }

  if (cronExpArray[2] !== '*' && cronExpArray[4] !== '*') {
    // Invalid base on AWS rules.
    return false;
  }

  /*
  if (cronExpArray[2] === '*' && cronExpArray[4] === '*') {
    // Invalid base on AWS rules.
    return false;
  }
  */

  try {
    parseOneRule(cronExpArray, 0, 0, 59);
    parseOneRule(cronExpArray, 1, 0, 23);

    let lastDate = 31;
    if (!isNaN(cronExpArray[3])) {
      if (cronExpArray[3] === '4' || cronExpArray[3] === '6' || cronExpArray[3] === '9' || cronExpArray[3] === '11') {
        lastDate = 30;
      }

      if (cronExpArray[3] === '2') {
        lastDate = 28;

        // @TODO: Should handle leap year.
      }
    }

    parseOneRule(cronExpArray, 2, 1, lastDate);
    parseOneRule(cronExpArray, 3, 1, 12);
    parseOneRule(cronExpArray, 4, 1, 7);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export default cronFormatValidator;