import api from './api';

import { getUserList, updateUser, createUser, deleteUser, getUser } from './users'
import { getPosList, updatePos, createPos, deletePos, getPos, getToken, getCompanies, getOutletsByCompanyId } from './pos'
import { getCommandList, updateCommand, createCommand, deleteCommand, getCommand } from './commands'
import { getQueueList, updateQueue, createQueue, deleteQueue, getQueue } from './queue'
import { getPingList } from './ping'
import { getCronTemplateList, updateCronTemplate, createCronTemplate, deleteCronTemplate, getCronTemplate } from './cronTemplates'
import { getCronList, updateCron, createCron, deleteCron, getCron } from './crons'
import { getMallTemplateList, updateMallTemplate, createMallTemplate, deleteMallTemplate, getMallTemplate } from './mallTemplates'
import { getMallIntegrationList, createMallIntegration, getMallIntegration, resendMallIntegration } from './mallIntegrations'
import { getBqIntegrationList, createBqIntegration, getBqIntegration, resendBqIntegration } from './bqIntegrations'

export default {
  getList: async (resource, params) => {
    if (resource === 'users') {
      return Promise.resolve(getUserList({ params, api }));
    } else if (resource === 'pos') {
      return Promise.resolve(getPosList({ params, api }));
    } else if (resource === 'commands') {
      return Promise.resolve(getCommandList({ params, api }));
    } else if (resource === 'queue') {
      return Promise.resolve(getQueueList({ params, api }));
    } else if (resource === 'ping') {
      return Promise.resolve(getPingList({ params, api }));
    } else if (resource === 'cronTemplates') {
      return Promise.resolve(getCronTemplateList({ params, api }));
    } else if (resource === 'crons') {
      return Promise.resolve(getCronList({ params, api }));
    } else if (resource === 'mallTemplates') {
      return Promise.resolve(getMallTemplateList({ params, api }));
    } else if (resource === 'mallIntegrations') {
      return Promise.resolve(getMallIntegrationList({ params, api }));
    } else if (resource === 'bqIntegrations') {
      return Promise.resolve(getBqIntegrationList({ params, api }));
    }

    return Promise.resolve({ data: [] });
  },

  getOne: (resource, params) => {
    if (resource === 'users') {
      return Promise.resolve(getUser({ params, api }));
    } else if (resource === 'pos') {
      return Promise.resolve(getPos({ params, api }));
    } else if (resource === 'commands') {
      return Promise.resolve(getCommand({ params, api }));
    } else if (resource === 'queue') {
      return Promise.resolve(getQueue({ params, api }));
    } else if (resource === 'cronTemplates') {
      return Promise.resolve(getCronTemplate({ params, api }));
    } else if (resource === 'crons') {
      return Promise.resolve(getCron({ params, api }));
    } else if (resource === 'mallTemplates') {
      return Promise.resolve(getMallTemplate({ params, api }));
    } else if (resource === 'mallIntegrations') {
      return Promise.resolve(getMallIntegration({ params, api }));
    } else if (resource === 'bqIntegrations') {
      return Promise.resolve(getBqIntegration({ params, api }));
    }

    return Promise.resolve({ data: [] });
  },

  getMany: (resource, params) => {
    return Promise.resolve({ data: [] });
  },

  getManyReference: (resource, params) => {
    return Promise.resolve({ data: [] });
  },

  update: (resource, params) => {
    if (resource === 'users') {
      return Promise.resolve(updateUser({ params, api }));
    } else if (resource === 'pos') {
      return Promise.resolve(updatePos({ params, api }));
    } else if (resource === 'commands') {
      return Promise.resolve(updateCommand({ params, api }));
    } else if (resource === 'queue') {
      return Promise.resolve(updateQueue({ params, api }));
    } else if (resource === 'cronTemplates') {
      return Promise.resolve(updateCronTemplate({ params, api }));
    } else if (resource === 'crons') {
      return Promise.resolve(updateCron({ params, api }));
    } else if (resource === 'mallTemplates') {
      return Promise.resolve(updateMallTemplate({ params, api }));
    }

    return Promise.resolve({ data: [] });
  },

  updateMany: (resource, params) => {
    return Promise.resolve({ data: [] });
  },

  create: (resource, params) => {
    if (resource === 'users') {
      return Promise.resolve(createUser({ params, api }));
    } else if (resource === 'pos') {
      return Promise.resolve(createPos({ params, api }));
    } else if (resource === 'commands') {
      return Promise.resolve(createCommand({ params, api }));
    } else if (resource === 'queue') {
      return Promise.resolve(createQueue({ params, api }));
    } else if (resource === 'cronTemplates') {
      return Promise.resolve(createCronTemplate({ params, api }));
    } else if (resource === 'crons') {
      return Promise.resolve(createCron({ params, api }));
    } else if (resource === 'mallTemplates') {
      return Promise.resolve(createMallTemplate({ params, api }));
    } else if (resource === 'mallIntegrations') {
      return Promise.resolve(createMallIntegration({ params, api }));
    } else if (resource === 'bqIntegrations') {
      return Promise.resolve(createBqIntegration({ params, api }));
    }

    return Promise.resolve({ data: [] });
  },

  delete: (resource, params) => {
    if (resource === 'users') {
      return Promise.resolve(deleteUser({ params, api }));
    } else if (resource === 'pos') {
      return Promise.resolve(deletePos({ params, api }));
    } else if (resource === 'commands') {
      return Promise.resolve(deleteCommand({ params, api }));
    } else if (resource === 'queue') {
      return Promise.resolve(deleteQueue({ params, api }));
    } else if (resource === 'cronTemplates') {
      return Promise.resolve(deleteCronTemplate({ params, api }));
    } else if (resource === 'crons') {
      return Promise.resolve(deleteCron({ params, api }));
    } else if (resource === 'mallTemplates') {
      return Promise.resolve(deleteMallTemplate({ params, api }));
    }

    return Promise.resolve({ data: [] });
  },

  deleteMany: (resource, params) => {
    return Promise.resolve({ data: [] });
  },

  getToken: (url) => {
    return Promise.resolve(getToken({ url, api }));
  },

  getCompanies: async (url) => {
    return Promise.resolve(getCompanies({ url, api }));
  },

  getOutletsByCompanyId: async (url) => {
    return Promise.resolve(getOutletsByCompanyId({ url, api }));
  },

  resendMallIntegration: async (url, data) => {
    return Promise.resolve(resendMallIntegration({ url, data, api }));
  },

  resendBqIntegration: async (url, data) => {
    return Promise.resolve(resendBqIntegration({ url, data, api }));
  }
};
