import React from 'react';

import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import Button from '@material-ui/core/Button';

import StringUtil from '../../utils/string';
import DateUtil from '../../utils/date';
import { TOKEN_KEY } from '../../utils/constants';

const { REACT_APP_API_URL } = process.env;

const download = ({ id }) => {
  const url = `${REACT_APP_API_URL}cron/${id}/download`;
  const token = localStorage.getItem(TOKEN_KEY);

  fetch(url, {
    method: 'GET',
    redirect: 'manual',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(async (resp) => {
    const json = await resp.json();
    window.open(json.url);
  });
};

const FormTitle = ({ record }) => {
  let title = '';
  if (record) {
    if (record.companyName) {
      title = `Cron for ${record.companyName}`;
    } else {
      title = `Cron for ${record.companyId}`;
    }

    return <span>{`${title}`}</span>;
  }
};

const DownloadButton = ({ record }) => {
  if (!(record && record.result)) return <div />;

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => download(record)}
    >
      Download
    </Button>
  );
};

const CronShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <FunctionField
          label="Type"
          render={(record) => `${StringUtil.titleCase(record.type)}`}
        />

        <TextField source="command" label="SQL Query" />

        <TextField source="cronExpression" label="Cron" />

        <TextField source="companyName" label="Company Name" />

        <TextField source="companyId" label="Company ID" />

        <FunctionField
          label="POS ID(s)"
          render={(record) => `${record.posIds.join(', ')}`}
        />

        <FunctionField
          label="POS Name(s)"
          render={(record) => `${record.posNames.join(', ')}`}
        />

        <FunctionField
          label="Accepted"
          render={(record) => `${DateUtil.dateTimeFormat(record.acceptedAt)}`}
        />

        <FunctionField
          label="Uploaded"
          render={(record) => `${DateUtil.dateTimeFormat(record.doneAt)}`}
        />
        <DownloadButton />
      </SimpleShowLayout>
    </Show>
  );
};

export default CronShow;
