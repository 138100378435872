import React from 'react';

import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';

const FormTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const CronTemplateShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="Name" />

        <TextField source="cronExpression" label="Cron Expression" />

        <FunctionField
          source="followUtc"
          label="UTC Time"
          render={(record) => (record.followUtc ? 'Yes' : 'No')}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default CronTemplateShow;
