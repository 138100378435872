import React from 'react';

import { Filter, FunctionField, List, Datagrid, TextField, EditButton, ShowButton, TextInput } from 'react-admin';

import CronEmpty from './empty';

import StringUtil from '../../utils/string';
import DateUtil from '../../utils/date';


const CronFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Type" source="type" allowEmpty />
    <TextInput label="Company ID" source="companyId" allowEmpty />
    <TextInput label="POS Name(s)" source="posNames" allowEmpty />
    <TextInput label="Created By" source="createdBy" allowEmpty />

    <TextField source="createdBy" label="Created By" />
    <TextField source="updatedBy" label="Updated By" />
  </Filter>
);


const CronList = (props) => {
  const getAuthor = (record) => {
    let author = '';
    if (record) {
      author = record.updatedBy ? record.updatedBy : record.createdBy;
    }

    return StringUtil.titleCase(author);
  }


  return (
    <List
      empty={<CronEmpty />}
      filters={<CronFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      title="Crons"
      {...props}
    >
      <Datagrid>
        <FunctionField label="Type" render={record => `${StringUtil.titleCase(record.type)}`} />
        <TextField label='Company Name' source='companyName' />

        <FunctionField
          label="POS Name(s)"
          render={(record) => `${(record.posNames || []).join(', ')}`}
        />

        <FunctionField label="Updated" render={record => `${DateUtil.dateTimeFormat(record.updatedAt)}`} />

        <FunctionField label="Updated By" render={record => getAuthor(record)} />

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CronList;
