import React from 'react';

import Button from '@material-ui/core/Button';

import { useDataProvider } from 'react-admin';

const BackupButton = ({ record, showAlert }) => {
  const dataProvider = useDataProvider();

  const { companyId, companyName, posId, posName } = record || {};

  const backupPos = ({ companyId, posId }) => {
    dataProvider
      .create('queue', {
        data: { companyId, companyName, posId, posName, type: 'backup' },
      })
      .then(({ data }) => {
        console.log(data);
        showAlert();
        // Update queue and ping list
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={() => {
        backupPos({ companyId, posId });
      }}
    >
      Backup
    </Button>
  );
};

export default BackupButton;
