import React from 'react';

import { Filter, TextInput, List, Datagrid, TextField, EditButton } from 'react-admin';

import UserEmpty from './empty';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" allowEmpty />
    <TextInput label="Email" source="email" allowEmpty />
  </Filter>
);

const UserList = (props) => {
  return (
    <List
      title='Users'
      empty={<UserEmpty />}
      filters={<UserFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="email" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
