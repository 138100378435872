import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Filter,
  DateField,
} from 'react-admin';
import moment from 'moment';

import PosEmpty from './empty';

const PosFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Company Name" source="companyName" allowEmpty />
    <TextInput label="Company ID" source="companyId" allowEmpty />
    <TextInput label="Pos Name" source="posName" allowEmpty />
    <TextInput label="Outlet Name" source="outletName" allowEmpty />
  </Filter>
);

const postRowStyle = (record) => {
  if (!record.lastPingAt) return {};
  const now = moment(new Date());
  const lastPingAt = moment(record.lastPingAt);
  const duration = moment.duration(now.diff(lastPingAt));
  const hours = duration.asHours();

  if (hours > 24) {
    return { backgroundColor: '#ffcdd2' };
  }
  if (hours > 1) {
    return { backgroundColor: '#fff9c4' };
  }

  return {};
};

const PosList = (props) => {
  return (
    <>
      <List
        empty={<PosEmpty />}
        filters={<PosFilter />}
        {...props}
        title="POS Terminals"
        bulkActionButtons={false}
      >
        <Datagrid rowStyle={postRowStyle}>
          <TextField label="Company Name" source="companyName" />
          <TextField label="POS Name" source="posName" />
          <TextField label="Outlet Name" source="outletName" />
          <DateField
            label="Last Ping"
            source="lastPingAt"
            showTime
            options={{
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }}
          />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default PosList;
