import React from 'react';

import { Create, SimpleForm, TextInput, required } from 'react-admin';

const CommandCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={[required()]} />
      <TextInput
        source="sql"
        label="SQL Query"
        validate={[required()]}
        fullWidth
        multiline
      />
    </SimpleForm>
  </Create>
);

export default CommandCreate;
