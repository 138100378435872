import React from 'react';

const CronTemplateNote = () => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <pre>
        <code>
          * * * * *<br />
          ┬ ┬ ┬ ┬ ┬<br />
          │ │ │ │ │<br />
          │ │ │ │ │<br />
          │ │ │ │ └─── day of week (0 - 7) (0 or 7 is Sun) <br />
          │ │ │ └───── month (1 - 12) <br />
          │ │ └─────── day of month (1 - 31) <br />
          │ └───────── hour (0 - 23) <br />
          └─────────── minute (0 - 59) <br />
        </code>
      </pre>

      <span className="cron-learn-more" >
        Click{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://crontab.guru/"
        >
          Cron Guru
        </a>{' '}
        for help.
      </span>
    </div>
  );
};

export default CronTemplateNote;
