import React from 'react';

import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

import Button from '@material-ui/core/Button';

const ShowMoreButton = ({ record, pathName, sortField = 'createdAt' }) => {
  const { companyId, posId } = record || {};

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      component={Link}
      to={{
        pathname: pathName,
        search: stringify({
          page: 1,
          perPage: 25,
          sort: sortField,
          order: 'DESC',
          filter: JSON.stringify({ companyId, posId })
        }),
      }}
    >
      Show More
    </Button>
  );
};

export default ShowMoreButton;
