import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loginTitle: {
    background: '#ccc',
    '& div.MuiAvatar-root': {
      width: 'auto',
      backgroundColor: '#fff',
      color: '#333',
      overflow: 'visible',
      '&:after': {
        color: '#333',
        backgroundColor: '#fff',
        paddingTop: '5px',
        paddingLeft: '10px',
        content: '" Equip Support "',
      },
    },
    fontWeight: 'bold',
  },
});

const styles = {
  main: {},
  card: {},
  icon: {},
  avatar: {},
};

const CustomStyledLoginForm = (props) => {
  const classes = useStyles();
  return <Login className={classes.loginTitle} {...props} />;
};

const LoginPage = withStyles(styles)(CustomStyledLoginForm);

export default LoginPage;
