import React from 'react';

import { Edit, SimpleForm, TextInput, required } from 'react-admin';

import EditToolbar from '../common/adminOnlyDeleteToolbar';

const FormTitle = ({ record }) => {
  return <span>Edit {record ? `${record.name}` : ''}</span>;
};

const CommandEdit = ({ permissions, ...props }) => (
  <Edit title={<FormTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar permissions={permissions} />}>
      <TextInput source="name" validate={[required()]} />
      <TextInput
        source="sql"
        label="SQL Query"
        validate={[required()]}
        fullWidth
        multiline
      />
    </SimpleForm>
  </Edit>
);

export default CommandEdit;
