import React, { useState, useEffect } from 'react';
import { useForm } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  BooleanInput,
  useDataProvider,
  SimpleForm,
  AutocompleteInput,
  TextInput,
  FormDataConsumer,
} from 'react-admin';

const FormCatcher = ({
  formData,
  record,
  availableCommands,
  setAvailableCommands,
  availableCommandsObject,
  editableCommand,
  setEditableCommand,
  clearTs,
  setClearTs,
  forceEditCommand,
  setForceEditCommand,
  data,
  setData,
  ...rest
}) => {
  const form = useForm();

  useEffect(() => {
    form.resetFieldState('name');
    form.change('name', null);
    form.resetFieldState('command');
    form.change('command', null);
    setEditableCommand(true);
  }, [form, clearTs, setEditableCommand, setData]);

  return (
    <>
      <div className="autocompute-field">
        <AutocompleteInput
          allowEmpty
          source="name"
          label="Name"
          choices={availableCommands || []}
          onChange={(e) => {
            const updatedData = { ...data };

            form.resetFieldState('command');
            form.change('command', null);

            if (e && e !== null) {
              form.change('command', availableCommandsObject[e].sql);
              setEditableCommand(false);
              updatedData['commandId'] = e;
              updatedData['commandName'] = availableCommandsObject[e].name;
              updatedData['commandValue'] = availableCommandsObject[e].sql;
              updatedData['command'] = availableCommandsObject[e].sql;
            } else {
              setEditableCommand(true);
              updatedData['commandId'] = '';
              updatedData['commandName'] = '';
              updatedData['commandValue'] = '';
              updatedData['command'] = '';
            }

            setData(updatedData);
          }}
        />
      </div>

      <TextInput
        source="command"
        fullWidth
        onChange={(e) => {
          const updatedData = { ...data };
          updatedData['command'] = e.currentTarget.value;
          setData(updatedData);
        }}
        disabled={!editableCommand && !forceEditCommand}
        multiline
      />

      {!editableCommand ? (
        <BooleanInput
          label="Edit selected command"
          source="edit"
          onChange={(e) => {
            setForceEditCommand(!forceEditCommand);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

const GetInformationButton = ({ record, showAlert }) => {
  const dataProvider = useDataProvider();

  const { companyId, posId } = record || {};

  const [open, setOpen] = useState(false);
  const [availableCommands, setAvailableCommands] = useState([]);
  const [availableCommandsObject, setAvailableCommandsObject] = useState({});
  const [editableCommand, setEditableCommand] = useState(true);
  const [forceEditCommand, setForceEditCommand] = useState(false);
  const [clearTs, setClearTs] = useState(true);

  const [data, setData] = useState({
    commandId: '',
    commandName: '',
    commandValue: '',
    command: '',
    edited: false,
  });

  const getCommandList = async () => {
    const filter = {};
    const pagination = { page: 1, perPage: 1000 };
    const sort = { field: 'id', order: 'ASC' };

    const commands = [];
    const commandsObj = [];
    await dataProvider
      .getList('commands', { filter, pagination, sort })
      .then(({ data }) => {
        data.forEach((value) => {
          commands.push({
            id: value.id,
            name: value.name,
          });

          commandsObj.push(value);
        });

        setAvailableCommands(commands);
        setAvailableCommandsObject(arrayToObject(commandsObj, 'id'));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickOpen = async () => {
    await getCommandList();
    setOpen(true);
  };

  const handleClear = () => {
    setClearTs(new Date().getTime());
    setData({
      commandId: '',
      commandName: '',
      commandValue: '',
      command: '',
      edited: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!(record && record.companyId && record.posId)) {
      console.log('Record identification not found.');
      return;
    }
    if (!data.command) {
      console.log('Command is missing');
      return;
    }

    const newData = { ...data };
    newData.companyId = record.companyId;
    newData.companyName = record.companyName;
    newData.posId = record.posId;
    newData.posName = record.posName;
    newData.type = 'command';
    newData.edited =
      newData.commandId && newData.commandValue !== newData.command;

    dataProvider
      .create('queue', { data: { ...newData } })
      .then(({ data }) => {
        showAlert();
        // Update queue and ping list
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Request Information
      </Button>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Add command for POS ${companyId}-${posId}`}</DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={null}>
            <FormDataConsumer>
              {(formDataProps) => (
                <FormCatcher
                  {...formDataProps}
                  record={record}
                  availableCommands={availableCommands}
                  setAvailableCommands={setAvailableCommands}
                  availableCommandsObject={availableCommandsObject}
                  editableCommand={editableCommand}
                  setEditableCommand={setEditableCommand}
                  clearTs={clearTs}
                  forceEditCommand={forceEditCommand}
                  setForceEditCommand={setForceEditCommand}
                  data={data}
                  setData={setData}
                />
              )}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
        <DialogActions style={{ paddingRight: '30px' }}>
          <Button onClick={handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Request
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GetInformationButton;
