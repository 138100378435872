const deleteUser = async ({ params, api }) => {
  const { previousData } = params;
  const { username } = previousData;
  return await api(`users/${username}`, { body: { username }, method: 'DELETE' })
    .then((res) => {
      return Promise.resolve({ data: res });
    })
};

export default deleteUser;
