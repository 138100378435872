import React from "react";

import { Create, PasswordInput, SelectInput, SimpleForm, TextInput, email, required } from "react-admin";

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" >
      <TextInput source="name" validate={[required()]} />
      <TextInput source="username" validate={[required()]} />
      <PasswordInput source="password" validate={[required()]} />
      <TextInput source="email" type="email" validate={[required(), email()]} />
      <SelectInput source="role" validate={[required()]} choices={[
        { id: 'admin', name: 'Admin' },
        { id: 'user', name: 'User' },
      ]} />
    </SimpleForm>
  </Create>
);

export default UserCreate;
