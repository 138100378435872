import React from 'react';

import { DeleteButton, SaveButton, Toolbar, } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const EditToolbar = ({ permissions, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {permissions === 'admin' && <DeleteButton />}
    </Toolbar>
  )
};

export default EditToolbar;