import React from 'react';

import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import CronTemplateNote from '../widget/cronTemplateNote';

import EditToolbar from '../common/adminOnlyDeleteToolbar';

import cronFormatValidator from '../utils/cronFormatValidator';

const validator = (values) => {
  const errors = {};

  if (values && values.cronExpression) {
    const isValidCronExpression = cronFormatValidator({
      cronExpression: values.cronExpression,
    });
    if (!isValidCronExpression) {
      errors.cronExpression = ['Invalid format'];
    }
  }

  return errors;
};

const FormTitle = ({ record }) => {
  return <span>Edit {record ? `${record.name}` : ''}</span>;
};

const CronTemplateEdit = ({ permissions, ...props }) => (
  <Edit title={<FormTitle />} {...props}>
    <SimpleForm
      validate={validator}
      toolbar={<EditToolbar permissions={permissions} />}
    >
      <TextInput source="name" validate={[required()]} />

      <BooleanInput label="UTC Time" source="followUtc" />

      <TextInput
        source="cronExpression"
        label="Cron Expression"
        placeholder="0 4 * * *"
        validate={[required()]}
      />

      <CronTemplateNote />
    </SimpleForm>
  </Edit>
);

export default CronTemplateEdit;
