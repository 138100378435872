import React from 'react';

import {
  FunctionField,
  ShowButton,
  List,
  NullableBooleanInput,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  Filter,
} from 'react-admin';

import CronTemplateEmpty from './empty';

import StringUtil from '../../utils/string';

const CronTemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" allowEmpty />
    <TextInput label="Cron Expression" source="cronExpression" allowEmpty />
    <NullableBooleanInput
      label="UTC Time"
      helperText={false}
      source="followUtc"
      displayNull
    />
    <TextInput label="Created by" source="createdBy" allowEmpty />
  </Filter>
);

const CronTemplateList = (props) => {
  const getAuthor = (record) => {
    let author = '';
    if (record) {
      author = record.updatedBy ? record.updatedBy : record.createdBy;
    }

    return StringUtil.titleCase(author);
  };

  return (
    <List
      title="Cron Templates"
      empty={<CronTemplateEmpty />}
      filters={<CronTemplateFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Name" />
        <TextField source="cronExpression" label="Cron Expression" />
        <FunctionField
          label="UTC Time"
          render={(record) => (record.followUtc ? 'Yes' : 'No')}
          source="followUtc"
        />
        <FunctionField
          label="Updated By"
          render={(record) => getAuthor(record)}
          source="username"
        />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CronTemplateList;
