import React from 'react';

import { PasswordInput, Edit, SelectInput, SimpleForm, TextInput, email, required } from 'react-admin';

import EditToolbar from '../common/adminOnlyDeleteToolbar';

const FormTitle = ({ record }) => {
  return <span>Edit {record ? `${record.name}` : ''}</span>;
};

const UserEdit = ({ permissions, ...props }) => (
  <Edit title={<FormTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar permissions={permissions} />}>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="username" validate={[required()]} />
      <PasswordInput source="password" />
      <TextInput source="email" type="email" validate={[required(), email()]} />
      <SelectInput source="role" validate={[required()]} choices={[
        { id: 'admin', name: 'Admin' },
        { id: 'user', name: 'User' },
      ]} />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
