import React from 'react';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import PingIcon from '@material-ui/icons/Contactless';

const PingEmpty = ({ basePath, resource }) => (
  <Box textAlign="center" m={1}>
    <PingIcon style={{ fontSize: 140 }} />
    <Typography variant="h4" paragraph>
      No pings available yet.
    </Typography>
  </Box>
);

export default PingEmpty;
