import api from './api';

import { TOKEN_KEY, USER_ROLE } from '../utils/constants';

export default {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    let res = {};
    try {
      res = await api("session", { body: { username, password } });
    } catch (e) {
      return Promise.reject(e);
    }

    const { token, role } = res;
    if (token && role) {
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem(USER_ROLE, role);
      return Promise.resolve();
    }

    return Promise.reject({});
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_ROLE);

    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_ROLE);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem(TOKEN_KEY) && localStorage.getItem(USER_ROLE) ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem(USER_ROLE);
    return role ? Promise.resolve(role) : Promise.reject();
  }
};
