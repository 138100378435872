import React from 'react';

import { Admin, Resource } from 'react-admin';

import authProvider from './context/authProvider';
import dataProvider from './context/dataProvider';

import { LoginPage, LogoutButton } from './components/admin';

import { UserList, UserCreate, UserEdit } from './components/users';
import { PosList, PosCreate, PosEdit, PosShow } from './components/pos';
import {
  CommandList,
  CommandCreate,
  CommandEdit,
  CommandShow,
} from './components/commands';
import {
  QueueList,
  QueueCreate,
  QueueEdit,
  QueueShow,
} from './components/queue';
import {
  CronTemplateList,
  CronTemplateCreate,
  CronTemplateEdit,
  CronTemplateShow,
} from './components/cronTemplates';
import { PingList } from './components/ping';
import { CronList, CronCreate, CronEdit, CronShow } from './components/crons';
import { Dashboard } from './components/dashboard';
import {
  MallTemplateList,
  MallTemplateCreate,
  MallTemplateEdit,
  MallTemplateShow,
} from './components/mallTemplates';
import {
  MallIntegrationList,
  MallIntegrationCreate,
  MallIntegrationShow,
} from './components/mallIntegrations';
import {
  BqIntegrationList,
  BqIntegrationCreate,
  BqIntegrationShow,
} from './components/bqIntegrations';

import UserIcon from '@material-ui/icons/Group';
import PosIcon from '@material-ui/icons/Apps';
import CommandIcon from '@material-ui/icons/EventNote';
import QueueIcon from '@material-ui/icons/RecentActors';
import CronTemplateIcon from '@material-ui/icons/Timer';
import CronIcon from '@material-ui/icons/AvTimer';
import PingIcon from '@material-ui/icons/Contactless';
import MallTemplateIcon from '@material-ui/icons/LocalMall';
import MallIntegrationIcon from '@material-ui/icons/CompareArrows';
import BqIntegrationIcon from '@material-ui/icons/CompareArrows';

const { REACT_APP__INTEGRATION } = process.env;

const App = () => (
  <>
    <Admin
      title="Equip Support"
      loginPage={LoginPage}
      logoutButton={LogoutButton}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {(permissions) => [
        // Restrict access to the edit and remove views to admin only
        <Resource
          name="pos"
          options={{ label: 'POS Terminals' }}
          list={PosList}
          edit={PosEdit}
          create={PosCreate}
          show={PosShow}
          icon={PosIcon}
        />,

        <Resource
          name="commands"
          options={{ label: 'Commands' }}
          list={CommandList}
          edit={CommandEdit}
          create={CommandCreate}
          show={CommandShow}
          icon={CommandIcon}
        />,

        <Resource
          name="queue"
          options={{ label: 'Jobs' }}
          list={QueueList}
          edit={QueueEdit}
          create={QueueCreate}
          show={QueueShow}
          icon={QueueIcon}
        />,

        <Resource
          name="cronTemplates"
          options={{ label: 'Cron Templates' }}
          list={CronTemplateList}
          edit={CronTemplateEdit}
          create={CronTemplateCreate}
          show={CronTemplateShow}
          icon={CronTemplateIcon}
        />,

        <Resource
          name="crons"
          options={{ label: 'Crons' }}
          list={CronList}
          edit={CronEdit}
          create={CronCreate}
          show={CronShow}
          icon={CronIcon}
        />,

        <Resource
          name="ping"
          options={{ label: 'Pings' }}
          list={PingList}
          icon={PingIcon}
        />,

        REACT_APP__INTEGRATION === 'true' ? (
          <Resource
            name="mallTemplates"
            options={{ label: 'Mall Templates' }}
            list={MallTemplateList}
            edit={MallTemplateEdit}
            create={MallTemplateCreate}
            show={MallTemplateShow}
            icon={MallTemplateIcon}
          />
        ) : null,

        REACT_APP__INTEGRATION === 'true' ? (
          <Resource
            name="mallIntegrations"
            options={{ label: 'Mall Integrations' }}
            list={MallIntegrationList}
            create={MallIntegrationCreate}
            show={MallIntegrationShow}
            icon={MallIntegrationIcon}
          />
        ) : null,

        REACT_APP__INTEGRATION === 'true' ? (
          <Resource
            name="bqIntegrations"
            options={{ label: 'BQ Integrations' }}
            list={BqIntegrationList}
            create={BqIntegrationCreate}
            show={BqIntegrationShow}
            icon={BqIntegrationIcon}
          />
        ) : null,

        permissions === 'admin' ? (
          <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
            icon={UserIcon}
          />
        ) : null,
      ]}
    </Admin>
  </>
);

export default App;
