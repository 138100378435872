import React, { useState, useEffect } from 'react';
import { useForm } from 'react-final-form';

import {
  AutocompleteInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  required,
  SimpleForm,
  TextInput,
  useDataProvider
} from 'react-admin';

const FormCatcher = ({ formData, ...rest }) => {
  const form = useForm();

  const dataProvider = useDataProvider();

  const [commandType, setCommandType] = useState('');
  const [posRecords, setPosRecords] = useState([]);
  const [posObject, setPosObject] = useState({});
  const [companies, setCompanies] = useState([]);
  const [companiesObject, setCompaniesObject] = useState({});
  const [availableCommands, setAvailableCommands] = useState([]);
  const [availableCommandsObject, setAvailableCommandsObject] = useState({});
  const [forceEditCommand, setForceEditCommand] = useState(false);
  const [editableCommand, setEditableCommand] = useState(false);

  const updateChoices = async (companyId) => {
    if (!companyId) {
      setPosRecords([]);
      return;
    }

    if (posRecords) {
      const filter = { companyId };
      const pagination = { page: 1, perPage: 1000 };
      const sort = { field: 'posId', order: 'DESC' };

      await dataProvider
        .getList('pos', { filter, pagination, sort })
        .then(({ data }) => {
          const companyPos = [];
          const companyPosObject = [];
          data.forEach((value) => {
            companyPos.push(
              {
                id: value.posId,
                name: value.posName
              }
            )

            companyPosObject.push(value);
          });

          setPosRecords(companyPos);
          setPosObject(arrayToObject(companyPosObject, 'posId'));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item
      return obj
    }, {})

  useEffect(() => {
    dataProvider
      .getCompanies('pos/companies')
      .then(({ data }) => {
        if (data && data.list) {
          const companyList = [];
          data.list.forEach((company) => {
            const { companyId, companyName } = company.id
            companyList.push({
              id: companyId,
              name: companyName
            })
          });

          setCompanies(companyList);
          setCompaniesObject(arrayToObject(companyList, 'id'));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const filterCommands = {};
    const paginationCommands = { page: 1, perPage: 1000 };
    const sortCommands = { field: 'id', order: 'ASC' };

    const commands = [];
    const commandsObj = [];
    dataProvider
      .getList('commands', {
        filter: filterCommands,
        pagination: paginationCommands,
        sort: sortCommands
      })
      .then(({ data }) => {
        data.forEach((value) => {
          commands.push({
            id: value.id,
            name: value.name,
          });

          commandsObj.push(value);
        });

        setAvailableCommands(commands);
        setAvailableCommandsObject(arrayToObject(commandsObj, 'id'));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);

  const onChangeCommandType = (cType) => {
    setCommandType(cType);

    if (cType !== 'command') {
      form.change('name', null);
      form.change('command', null);
      setForceEditCommand(false);
    }
  }

  const onChangeCommandTemplate = (commandId) => {
    if (commandId && commandId !== null) {
      setEditableCommand(false);
      form.change('command', availableCommandsObject[commandId].sql);
      form.change('commandName', availableCommandsObject[commandId].name);
      form.change('commandValue', availableCommandsObject[commandId].sql);
    } else {
      setEditableCommand(true);
      form.change('command', '');
      form.change('commandName', '');
      form.change('commandValue', '');
    }
  }

  return (
    <div className='cron-form'>
      <div className="autocompute-field">
        <AutocompleteInput
          source="type"
          validate={[required()]}
          choices={[
            { id: 'backup', name: 'Backup' },
            { id: 'command', name: 'Command' },
          ]}
          onChange={onChangeCommandType}
        />
      </div>

      {
        commandType === 'command' ? (
          <>
            <div className="autocompute-field">
              <AutocompleteInput
                allowEmpty
                source="commandId"
                label="Name"
                choices={availableCommands || []}
                onChange={onChangeCommandTemplate}
                disabled={commandType !== 'command'}
              />
            </div>

            <div>
              <TextInput
                source="command"
                fullWidth
                disabled={!editableCommand && !forceEditCommand}
                multiline
              />
            </div>

            {!editableCommand ? (
              <BooleanInput
                label="Edit selected command"
                source="edit"
                onChange={(e) => {
                  setForceEditCommand(!forceEditCommand);
                }}
              />
            ) : (
                ''
              )}
          </>
        ) : (
            ''
          )
      }

      <TextInput type='hidden' source="companyName" style={{ display: 'none' }} />

      <div>
        <div style={{ width: '246px' }}>
          <AutocompleteInput
            source="companyId"
            onChange={(e) => {
              form.change('companyName', companiesObject[e].name);
              form.change('posId', undefined);
              updateChoices(e)
            }} choices={companies || []}
          />
        </div>
      </div>

      <div>
        <AutocompleteInput
          source="posId"
          label="POS ID"
          choices={posRecords}
          validate={[required()]}
          onChange={(e) => {
            form.change('posName', posObject[e].posName);
          }}
        />
      </div>

      <TextInput type='hidden' source="posName" style={{ display: 'none' }} />
      <TextInput type='hidden' source="commandId" style={{ display: 'none' }} />
      <TextInput type='hidden' source="commandName" style={{ display: 'none' }} />
      <TextInput type='hidden' source="companyValue" style={{ display: 'none' }} />
    </div>
  )
};

const validator = (values) => {
  const errors = {};

  const isCommand = values.type === 'command';
  const isCommandEmpty = ((!values.command) || values.command === '' || values.command === null);

  if (isCommand && isCommandEmpty) {
    errors.command = ['Required'];
  }

  return errors;
};

const FormTitle = ({ record }) => {
  return <span>Create Job</span>;
};

const QueueCreate = (props) => (
  <Create title={<FormTitle />} {...props}>
    <SimpleForm redirect="list" validate={validator}>
      <FormDataConsumer>
        {formDataProps => (
          <FormCatcher {...formDataProps} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default QueueCreate;