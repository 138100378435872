import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useDataProvider } from 'react-admin';

import DateUtil from '../../../utils/date';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PingTable({ record = {} }) {
  const dataProvider = useDataProvider();

  const [pings, setPings] = useState([]);

  const selectedCompanyId = record.companyId;
  const selectedPosId = record.posId;

  useEffect(() => {
    if (!(selectedCompanyId && selectedPosId)) return;

    const filter = { companyId: selectedCompanyId, posId: selectedPosId };
    const pagination = { page: 1, perPage: 5 };
    const sort = { field: 'createdAt', order: 'DESC' };

    dataProvider
      .getList('ping', { filter, pagination, sort })
      .then(({ data }) => {
        setPings(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider, selectedCompanyId, selectedPosId]);

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Accepted Job ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pings.map((row) => (
            <TableRow key={`${row.companyId}-${row.posId}-${row.createdAt}`}>
              <TableCell component="th" scope="row">
                {DateUtil.dateTimeFormat(row.createdAt)}
              </TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell>{row.acceptedJobId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
