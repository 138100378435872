import React, { useState } from 'react';

import {
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar,
  useShowController,
  DateField,
  FunctionField,
} from 'react-admin';

import { Box, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import BackupButton from './actions/backupButton';
import GetInformationButton from './actions/getInformationButton';
import ShowMoreButton from './actions/showMoreButton';

import QueueTable from './list/queueTable';
import PingTable from './list/pingTable';

import { useDataProvider } from 'react-admin';

const FormTitle = ({ record }) => {
  return <span>{record ? `${record.companyName} - ${record.posId}` : ''}</span>;
};

const PosShowActions = ({ basePath, data, showAlert }) => (
  <TopToolbar>
    <Box mx={1}>
      <EditButton basePath={basePath} record={data} />
    </Box>
    <Box mx={1}>
      <BackupButton basePath={basePath} record={data} showAlert={showAlert} />
    </Box>
    <Box mx={1}>
      <GetInformationButton
        basePath={basePath}
        record={data}
        showAlert={showAlert}
      />
    </Box>
  </TopToolbar>
);

const PosShow = ({ permissions, ...props }) => {
  const dataProvider = useDataProvider();

  const [posToken, setPosToken] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [refreshList, setRefreshList] = useState(0);

  const { record = {}, basePath } = useShowController(props);

  const RequestToken = ({ dataProvider, record }) => {
    const requestToken = (e) => {
      e.preventDefault();

      dataProvider
        .getToken(`pos/${record.id}/token`)
        .then(({ data }) => {
          setPosToken(data && data.token);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <a
        href="#request"
        onClick={(e) => {
          requestToken(e);
        }}
      >
        Show Token
      </a>
    );
  };

  const hideAlert = () => setShowAlert(false);

  return (
    <>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={hideAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={hideAlert} severity="success">
          Your request has been added to job queue!
        </Alert>
      </Snackbar>
      <Show
        title={<FormTitle />}
        actions={
          <PosShowActions
            showAlert={() => {
              setShowAlert(true);
              setRefreshList(refreshList + 1);
            }}
          />
        }
        {...props}
      >
        <TabbedShowLayout>
          <Tab label="summary">
            <TextField source="companyName" label="Company Name" />

            <TextField source="companyId" label="Company ID" />

            <TextField source="posName" label="POS Name" />

            <TextField source="posId" label="POS ID" />

            <TextField source="outletName" label="Outlet Name" />

            <TextField label="Created By" source="createdBy" />

            <DateField
              label="Last Ping"
              source="lastPingAt"
              showTime
              options={{
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              }}
            />

            {permissions === 'admin' ? (
              <FunctionField
                label="Token"
                render={(record) =>
                  posToken ? (
                    posToken
                  ) : (
                      <RequestToken dataProvider={dataProvider} record={record} />
                    )
                }
              />
            ) : (
                ''
              )}
          </Tab>
          <Tab label="Jobs" path="queue">
            <Box m={2}>
              <QueueTable record={record} refresh={refreshList} />

              <div style={{ textAlign: 'right', marginTop: '21px' }}>
                <ShowMoreButton
                  basePath={basePath}
                  record={record}
                  pathName="/queue"
                  sortField="updatedAt"
                />
              </div>
            </Box>
          </Tab>
          <Tab label="Pings" path="ping">
            <Box m={2}>
              <PingTable record={record} />

              <div style={{ textAlign: 'right', marginTop: '21px' }}>
                <ShowMoreButton
                  basePath={basePath}
                  record={record}
                  pathName="/ping"
                />
              </div>
            </Box>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default PosShow;
